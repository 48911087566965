import { Typography } from "@material-ui/core";
import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import useStyles from "../../assests/css/authStyles";
import CButton from "./CButton";
import { ReactComponent as VerificationOtpIcon } from "../../assests/icon/verifyOtpIcon.svg";
import OtpInput from "react-otp-input";
import CAlert from "../CAlert";
import { CContext } from "./CContext";

const ConfirmSignUp = ({
  formState,
  authState,
  onStateChange,
  updateFormState,
}) => {
  const classes = useStyles();
  const { email, authCode } = formState;
  const [error, setError] = useState({ state: false, message: "" });
  const { setisModalOpen } = React.useContext(CContext);
  const [isDisabled, setIsDisabled] = useState(false);
  const [alert, setAlert] = useState({ message: "", type: "" });

  useEffect(() => {
    setAlert({ message: "", type: "" });
  }, [authState]);
  const handleOTP = (otp) => {
    updateFormState({ ...formState, authCode: otp });
    if (error.state) {
      if (otp.length === 6) {
        setError({ state: false, message: "" });
      }
    }
  };
  const resendConfirmationCode = async () => {
    updateFormState({ ...formState, authCode: "" });
    setAlert({ message: "", type: "" });
    setError({ state: false, message: "" });
    try {
      let response = await Auth.resendSignUp(email);
      setAlert({
        message: `Verification code sent to ${response.CodeDeliveryDetails.Destination}`,
        type: "success",
      });
    } catch (error) {
      setAlert({ message: error.message, type: "error" });
    }
  };
  // const listenToAutoSignInEvent = async () => {
  // 	Hub.listen("auth", ({ payload }) => {
  // 		const { event } = payload;
  // 		if (event === "autoSignIn") {
  // 			const user = payload.data;
  // 			console.log("user :", user);
  // 			// assign user
  // 		} else if (event === "autoSignIn_failure") {
  // 			onStateChange("signIn");
  // 		}
  // 	});
  // };

  const confirmSignUp = async () => {
    setAlert({ message: "", type: "" });
    if (formState.authCode.length !== 6) {
      if (!formState.authCode.length) {
        setError({ state: true, message: "OTP cannot be empty" });
      } else {
        setError({
          state: true,
          message: "OTP should contain 6 digits",
        });
      }
    } else {
      setIsDisabled(true);
      try {
        await Auth.confirmSignUp(email, authCode);
        updateFormState({
          email: formState.email,
          authCode: formState.authCode,
        });
        try {
          await Auth.signIn(formState.email, formState.password);
          localStorage.setItem("IsProfileSettingUP", true);
          updateFormState({ email: "", password: "", authCode: "" });
          setIsDisabled(false);
          onStateChange("signedIn");
          setisModalOpen(true);
          // saveData();
        } catch (error) {
          updateFormState({ email: "", password: "", authCode: "" });
          setIsDisabled(false);
        }
      } catch (error) {
        setAlert({ message: error.message, type: "error" });
        updateFormState({ ...formState, authCode: "" });
        setError({ state: true, message: "" });
        setIsDisabled(false);
      }
    }
  };
  // const saveData = async () => {
  //   await ;
  // };

  return authState === "confirmSignUp" ? (
    <>
      <div className={classes.verificationConfirmSignUP}>
        {alert.message !== "" && (
          <CAlert
            message={alert.message}
            type={alert.type}
            setAlert={setAlert}
          />
        )}
        <div className={classes.VerificationContainer}>
          <div className={classes.verificationHeader}>
            <Typography align="center">Verification</Typography>
            <VerificationOtpIcon />
          </div>
          <div className={classes.verifyOTP}>
            <Typography align="center" className="title">
              Verification Code
            </Typography>
            <Typography align="center" className="subTitle">
              We have sent a verification code to your mail id
            </Typography>
            <div className="otpContainer">
              <OtpInput
                value={formState.authCode}
                onChange={handleOTP}
                numInputs={6}
                isInputNum={true}
                shouldAutoFocus
                inputStyle="otpInput"
                hasErrored={error.state}
                errorStyle="otpErrorState"
              />
              <p>{error.state ? error.message : "  "}</p>
            </div>
            <Typography
              className={classes.signInFooter}
              align="center"
              style={{ color: "#000" }}
            >
              If you didn't recieve a code!
              <span
                onClick={resendConfirmationCode}
                className={classes.signInFooterInverse}
                style={{marginLeft: '5px'}}
              >
                Resend
              </span>
            </Typography>
            <CButton
              variant="contained"
              onClick={() => confirmSignUp()}
              style={{ width: "60%", marginTop: 15 }}
              disabled={isDisabled}
            >
              Submit
            </CButton>
            <Typography
              className={
                classes.signInFooter + " " + classes.signInFooterInverse
              }
              align="center"
              style={{marginTop: '10px'}}
            >
              <span style={{ color: "black" }}> Back to </span>{" "}
              <span onClick={() => onStateChange("signIn")}>Sign In</span>
            </Typography>
          </div>
        </div>
      </div>
    </>
  ) : null;
  // <div>
  //   <Modal
  //     open={modalOpen}
  //     onClose={handleCloseNameImageUploadModal}
  //     aria-labelledby="modal-modal-title"
  //     aria-describedby="modal-modal-description"
  //   >
  //     <Box className={classes.modal + " " + classes.changePassword}>
  //       <Typography id="modal-modal-description">
  //         Enter your Details here
  //       </Typography>
  //       <Box className={classes.modalFooter}>
  //         <div style={{ flexDirection: "row", display: "flex" }}>
  //           <TextField
  //             fullWidth
  //             placeholder={"Enter Your name here"}
  //             autoComplete={false}
  //             required
  //           />
  //         </div>
  //         <div className={classes.modalFooter1}>
  //           <Button
  //             variant="text"
  //             size="medium"
  //             onClick={() => handleCloseName()}
  //           >
  //             Save
  //           </Button>
  //           <Button variant="text" size="medium">
  //             Cancel
  //           </Button>
  //         </div>
  //       </Box>
  //     </Box>
  //   </Modal>
  // </div>
};

export default ConfirmSignUp;
