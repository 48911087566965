import { InputAdornment, Typography, Grid, Button } from "@material-ui/core";
import { IconButton, TextField } from "@mui/material";
import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import CButton from "./CButton";
import { useForm, Controller } from "react-hook-form";
import { Box } from "@mui/system";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import useStyles from "../../assests/css/SignInCss";
import AuthPageImage from "./AuthPageImage";
import LoadingSpinner from "./LoadingSpinner";
import GroupIcon from "../../assests/images/Group.png";
import GroupPeople from "../../assests/images/GroupPeople.png";
import QeaseLogo from "../../assests/images/QeaseLogo.png";
import PoweredByLab from "../../assests/images/PoweredBy.svg";

const SignIn = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [alert, setAlert] = useState({ message: "", type: "" });
  const { reset, handleSubmit, control } = useForm();
  const { authState, onStateChange } = props;
  const classes = useStyles();
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    reset();
    setAlert({ message: "", type: "" });
  }, [authState]);

  const onSubmit = async (data) => {
    setIsDisabled(true);
    try {
      console.log("signIndata", data.email.toLowerCase(), data.password);
      await Auth.signIn(data.email.toLowerCase(), data.password);

      setIsDisabled(false);
    } catch (error) {
      setAlert({ message: error.message, type: "error" });
      setTimeout(() => {
        setAlert({ message: "", type: "" });
      }, 2500);
      setIsDisabled(false);
    }
  };

  return authState === "signIn" ? (
    <Box
      component="form"
      noValidate
      className={classes.root}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid
        container
        className={classes.containerHeight}
        style={{
          // margin: "auto",
          // padding: "20px 1.2rem",
          position: "relative",
          backgroundImage: `url(${GroupIcon})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        {/* <AuthPageImage PageName={"Login"} /> */}
        <img src={GroupPeople} />
        <Grid item xs={12} sm={12} md={6}>
          <div className={classes.signInContainer} style={{ margin: "auto" }}>
            <div className={classes.signIn}>
              <div
                style={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  display: "flex",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <img
                  src={QeaseLogo}
                  style={{ width: "45%" }}

                  // alt="fireSpot"
                />
              </div>

              <Typography className={classes.HeadingText}>Login</Typography>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{
                  required: "Email cannot be empty",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    id="outlined-error-helper-text"
                    label="Email"
                    // defaultValue="name@domain.com"
                    variant="outlined"
                    autoComplete="on"
                    value={value}
                    onChange={(e) => onChange(e.target.value.toLowerCase())}
                    placeholder="name@domain.com"
                    helperText={error ? error.message : " "}
                    error={!!error}
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                defaultValue=""
                rules={{ required: "Password cannot be empty" }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    id="outlined-error-helper-text"
                    label="Password"
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    autoComplete="on"
                    value={value}
                    onChange={onChange}
                    placeholder="Password"
                    helperText={error ? error.message : " "}
                    error={!!error}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    style={{ marginTop: '10px' }}
                  />
                )}
              />
              {alert.message !== "" && (
                <p
                  style={{
                    color: "red",
                    marginTop: "-25px",
                    marginBottom: "0px",
                    fontSize: "0.75rem",
                  }}
                >
                  {alert.message}
                </p>
              )}
              <Box sx={{ width: "100%" }} margin="auto">
                {/* <Typography
                  onClick={() => onStateChange("signUp")}
                  className={classes.hover}
                >
                  Sign Up
                </Typography> */}
                <Typography
                  onClick={() => onStateChange("forgotPassword")}
                  className={classes.hover}
                >
                  Forgot Password?
                </Typography>
                <CButton
                  type="submit"
                  variant="contained"
                  disabled={isDisabled}
                  style={{
                    position: "relative",
                    width: "88%",
                    display: "flex",
                    // width: "150px",
                    height: "33px",
                    padding: "9px 105px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    borderRadius: "25px",
                  }}
                >
                  LOGIN
                  {isDisabled ? <LoadingSpinner /> : null}
                </CButton>
                <div className={classes.BottomTextPosition}>
                  <Typography
                    onClick={() => onStateChange("signUp")}
                    style={{ color: "#878787" }}
                  >
                    Not Registered?
                    <span
                      className={classes.hover}
                      // style={{ marginLeft: "8px" }}
                    >
                      Sign Up
                    </span>
                  </Typography>
                </div>
                <a
                  href={"https://www.habilelabs.io/contact-us"}
                  style={{ textDecoration: "none" }}
                >
                  <div
                    style={{
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "row",
                      display: "flex",
                      paddingTop: "20px",
                      paddingBottom: "10px",
                    }}
                    onClick
                  >
                    <img src={PoweredByLab} style={{ width: "40%" }} />
                  </div>
                </a>
              </Box>
            </div>
          </div>
        </Grid>
      </Grid>
    </Box>
  ) : null;
};
export default SignIn;
