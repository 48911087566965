import { makeStyles } from "@material-ui/core";
import React from "react";
import { colorStyles } from "../../assests/css/color";

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "center",
    display: "flex",
    marginTop: 10,
    [theme.breakpoints.down("xs")]: {
      marginTop: 20,
      marginBottom: 15,
    },
    "& .contained": {
      color: "#FFFFFF",
      border: "none",
      background: colorStyles.secondaryColor,
      boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.25)",
      // borderRadius: 10,
      padding: "10px 0",
      width: "100%",
      "&:hover": {
        cursor: "pointer",
        background: "#0386ee", 
        boxShadow: "0px 4px 15px rgba(15, 82, 186, 0.5)",
        transition: "background 0.3s ease, box-shadow 0.3s ease",
      },
      "&:disabled": {
        cursor: "not-allowed",
        color: "gray",
        opacity: "0.7",
      },
    },
    "& .outlined": {
      border: "1px solid #D1CBCB",
      background: "none",
      // filter: "drop-shadow(4px 4px 20px rgba(0, 0, 0, 0.25))",
      // boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.25)",
      borderRadius: 10,
      padding: "10px 0",
      width: "100%",
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
}));

const CButton = (props) => {
  const { children, variant } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <button className={variant} {...props}>
        {children}
      </button>
    </div>
  );
};

export default CButton;
